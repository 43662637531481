import { Typography } from "@mui/material";

export default function BotChatMessage(props: any) {
  function replaceWithBr(text: string) {
    return text.replace(/\n/g, "<br>");
  }

  return (
    <Typography
      component="div"
      className="react-chatbot-kit-chat-bot-message typewriter"
    >
      {!props.message && props.loader}
      {props.message && (
        <>
          <span
            className="message"
            style={{ fontSize: 14 }}
            dangerouslySetInnerHTML={{
              __html: replaceWithBr(props.message),
            }}
          />
          <div className="react-chatbot-kit-chat-bot-message-arrow"></div>
        </>
      )}
    </Typography>
  );
}
