import React from "react";
// import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import "react-chatbot-kit/build/main.css";
import "./App.scss";
import { theme } from "./styles/theme";
import {  MsalProvider } from '@azure/msal-react';
import { AuthenticatedTemplate } from '@azure/msal-react'
import Router from "./routes";
import { IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: 'e359fdf8-c61c-4deb-b72e-6def105ae1dd',
    authority: `https://login.microsoftonline.com/30af61e6-f207-4ecc-97ac-2932bc0503dc`,
    redirectUri: '/',
  },
  cache: {
    cacheLocation: 'localStorage', // or 'sessionStorage'
    storeAuthStateInCookie: false,
  },
};

const pca = new PublicClientApplication(msalConfig);
type AppProps = {
  pca: IPublicClientApplication;
};
function App({ pca }: AppProps) {
  // const [searchQuery, setSearchQuery] = React.useState("");
  //const { instance, accounts } = useMsal();
 // const account = useAccount(accounts[0] || {});
  // allowedUsers = ['n.mayur.pimpalkar@accenture.com']
  

  return (
    <MsalProvider instance={pca}>
      <AuthenticatedTemplate>
    <ThemeProvider theme={theme}>
      <Router/>
      {/* <Dashboard/> */}
    </ThemeProvider>
</AuthenticatedTemplate>
    </MsalProvider>
  );
}

export default App;


