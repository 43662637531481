import React from "react";
import axios from "axios";
import LinearProgress from "@mui/material/LinearProgress";

import "./Products.style.scss";
import Product from "../../components/product/Product";
import { ProductListContext } from "../../contexts/ProductListContext";
import { GetProductsResponse, ProductObjType } from "../../types/products";

export default function Products() {
  const { products, updateProducts, loading, setLoading, clearFilters } =
    React.useContext(ProductListContext);

  React.useEffect(() => {
    fetchData();
  }, [clearFilters]);

  const fetchData = () => {
    setLoading(true);
    axios
      .get<GetProductsResponse>(
        // `https://ai-retail-cpr-pasfr-test.azurewebsites.net/api/productsForSearch?new=true`
        "https://ai-retail-gen-ai-nike-api.azurewebsites.net/products"
      )
      .then((response) => {
        updateProducts(response?.data?.products);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  return (
    <>
      {loading ? (
        <LinearProgress sx={{ flex: 1 }} />
      ) : (
        <div className="card-grid">
          {products &&
            products.map((product: ProductObjType) => (
              <Product key={product.productId} {...product} />
            ))}
        </div>
      )}
    </>
  );
}
