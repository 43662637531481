import { Configuration, PopupRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: "e359fdf8-c61c-4deb-b72e-6def105ae1dd",
        authority: "https://login.microsoftonline.com/30af61e6-f207-4ecc-97ac-2932bc0503dc",
        redirectUri: "",
        postLogoutRedirectUri: "/"
    },
    system: {
        allowNativeBroker: false // Disables WAM Broker
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: [ 'user.read',
    'profile',
    'openid','User.Read','GroupMember.Read.All','User.Read.All','Group.Read']
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};