import * as React from "react";
import {
    BrowserRouter,
    Routes,
    Route,
    Link,
  } from "react-router-dom";
import Dashboard from "./dashboard";
import NoAccess from "./noaccess";


function Router() {
  
  return (<>
   <Routes>
        <Route path="/noaccess" element={<NoAccess />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/" element={<Dashboard />} />
      </Routes>
  
  </>
   
  );
}
export default Router;
