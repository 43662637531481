import { useMsal } from "@azure/msal-react";
import NoAccess from "./noaccess";
import ResponsiveAppBar from "./components/appbar/Appbar";
import { BotVisibleProvider } from "./contexts/BotVisibleContext";
import ChatRequestPayloadProvider from "./contexts/ChatRequestPayloadContext";
import ProductListContextProvider from "./contexts/ProductListContext";
import ChatSideSheet from "./views/chat-side-sheet/ChatSideSheet";
import { Box } from "@mui/material";
import Products from "./views/products/Products";


function Dashboard() {
  const { accounts } = useMsal();
  const allowedGroup = "38882352-38c0-4e9f-a7e8-28c3a3b2bab3"; // Change this to your group name
  console.log("Accounts",accounts);
 
 const userGroups = (accounts[0]?.idTokenClaims?.groups || []) as string[];

 console.log("idTokenClaims", accounts[0]?.idTokenClaims?.groups);
  // Check if the user's ID is present in the allowed group
  const hasAccess = userGroups.includes(allowedGroup);
  console.log("has access",hasAccess);
  if (hasAccess) {
    return (
      <>
        <ProductListContextProvider>
          <div className="top-container">
            <ResponsiveAppBar />
            {/* <div className="search-container">
            <SearchBar
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
            <Typography component="div" sx={{ width: 20 }} />
            <Button variant="contained" startIcon={<FilterAltOffIcon />}>
              Clear Filters
            </Button>
          </div> */}
          </div>
          <Box className="center-panel">
            <Products />
            <BotVisibleProvider>
              <ChatRequestPayloadProvider>
                <ChatSideSheet />
              </ChatRequestPayloadProvider>
            </BotVisibleProvider>
            {/* <BotVisibleProvider>
          <ChatRequestPayloadProvider>
            <Chatbot />
          </ChatRequestPayloadProvider>
        </BotVisibleProvider> */}
          </Box>
        </ProductListContextProvider>
      </>
    );
  } else {
    return <NoAccess />;
  }
}
export default Dashboard;
