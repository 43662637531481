import React, { useRef, useEffect, useState } from "react";
import ChatbotKit from "react-chatbot-kit";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, IconButton } from "@mui/material";
import ThreePOutlinedIcon from "@mui/icons-material/ThreePOutlined";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import "./ChatSideSheet.style.scss";
import config from "../../bot/config";
import ActionProvider from "../../bot/ActionProvider";
import MessageParser from "../../bot/MessageParser";

export default function ChatSideSheet() {
  const [activeChat, setActiveChat] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [dragStartX, setDragStartX] = useState<number>(30);
  const [dragStartY, setDragStartY] = useState<number>(30);
  const [dragStartWidth, setDragStartWidth] = useState<number>(30);
  const [originalWidth, setOriginalWidth] = useState<number>(370); // Adjust the original width as needed
  const dragContainerRef = useRef<HTMLDivElement | null>(null);
  const offsetXRef = useRef<number | null>(null);
  const offsetYRef = useRef<number | null>(null);

  const onChatIconClick = () => {
    setActiveChat(true);
    dragContainerRef.current!.style.width = "370px";
  };

  const onBackIconClick = () => {
    setActiveChat(false);
    dragContainerRef.current!.style.width = "60px";
  };

  const saveMessages = (messages: string) => {
    localStorage.setItem("chat_messages", JSON.stringify(messages ?? {}));
  };

  const loadMessages = () => {
    const messages = JSON.parse(
      localStorage.getItem("chat_messages") ?? "{}"
    );
    return messages;
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsDragging(true);
    setDragStartX(event.clientX);
    setDragStartY(event.clientY);
    setDragStartWidth(dragContainerRef.current!.offsetWidth);
    offsetXRef.current = event.clientX - dragContainerRef.current!.offsetLeft;
    offsetYRef.current = event.clientY - dragContainerRef.current!.offsetTop;
  };
const handleMouseMove = (event: MouseEvent) => {
  if (!isDragging) return;

  const widthChange = dragStartX - event.clientX;
  const newWidth = dragStartWidth + widthChange;
  const maxWidth = window.innerWidth * 0.6; // Maximum width of 60% of the screen

  if (newWidth >= dragContainerRef.current!.offsetWidth && newWidth <= maxWidth) {
    dragContainerRef.current!.style.width = `${newWidth}px`;
  }

  const left = event.clientX - offsetXRef.current!;
  const top = event.clientY - offsetYRef.current!;

  const maxDragDistance = window.innerWidth * 0.1; // Limit the dragging distance to 10% of the screen width
  const minDragX = dragStartX - maxDragDistance;
  const maxDragX = dragStartX; // Restrict dragging only towards the left

  const newLeft = Math.min(Math.max(left, minDragX), dragStartX); // Adjust the left position calculation

  if (event.clientX >= minDragX && event.clientX <= maxDragX) {
    dragContainerRef.current!.style.left = `${newLeft}px`; // Update the left position
  }

  dragContainerRef.current!.style.top = `${top}px`;
};


  const handleMouseUp = () => {
    setIsDragging(false);
    offsetXRef.current = null;
    offsetYRef.current = null;
  };

  useEffect(() => {
    const handleDocumentMouseMove = (event: MouseEvent) => {
      handleMouseMove(event);
    };

    const handleDocumentMouseUp = () => {
      handleMouseUp();
    };

    if (isDragging) {
      document.addEventListener("mousemove", handleDocumentMouseMove);
      document.addEventListener("mouseup", handleDocumentMouseUp);
    } else {
      document.removeEventListener("mousemove", handleDocumentMouseMove);
      document.removeEventListener("mouseup", handleDocumentMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleDocumentMouseMove);
      document.removeEventListener("mouseup", handleDocumentMouseUp);
    };
  }, [isDragging]);

  const resetWidth = () => {
    const currentWidth = parseInt(dragContainerRef.current!.style.width, 10);
    const originalWidth = currentWidth - 40;
    dragContainerRef.current!.style.width = `${originalWidth}px`;
  };

  const expandWidth = () => {
    const currentWidth = dragContainerRef.current!.offsetWidth;
    const newWidth = currentWidth + 50; // Adjust the value as needed
    const maxWidth = window.innerWidth * 0.6; // Maximum width of 60% of the screen

    if (newWidth <= maxWidth) {
      dragContainerRef.current!.style.width = `${newWidth}px`;
    }
  };

  const width = activeChat ? originalWidth : 60;

  return (
    <Box className="docking-container" ref={dragContainerRef} sx={{ width }}>
      {activeChat ? (
        <ChatbotKit
          config={config}
          actionProvider={ActionProvider}
          messageParser={MessageParser}
          messageHistory={loadMessages()}
          saveMessages={saveMessages}
          runInitialMessagesWithHistory={true}
        />
      ) : (
        <Box sx={{ flex: 1 }}></Box>
      )}

      <Box className="buttons-container">
        {activeChat ? (
          <Box component="div" sx={{ display: "flex", width: "100%" }}>
            <IconButton onClick={onBackIconClick}>
              <ChevronRightIcon fontSize="large" />
            </IconButton>
            <Box sx={{ flex: 1 }} />
          </Box>
        ) : (
          <IconButton
            sx={{ margin: "auto" }}
            color="primary"
            onClick={onChatIconClick}
          >
            <ThreePOutlinedIcon fontSize="large" />
          </IconButton>
        )}

        {/* Draggable Icons */}
        {activeChat && (
          <>
            <IconButton
              sx={{
                margin: "auto",
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: 1, // Increase the zIndex to ensure the icon is on top of other elements
            
              }}
              onClick={expandWidth}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
            >
              <ChevronLeftIcon />
            </IconButton>
            <IconButton
              sx={{
                position: "absolute",
                top: "50%",
                right: -5,
                transform: "translateY(-50%)",
              }}
              onClick={resetWidth}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
            >
              <ChevronRightIcon />
            </IconButton>
          </>
        )}
      </Box>
    </Box>
  );
}
