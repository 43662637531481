import * as React from "react";
import './noaccess.css'

function NoAccess() {
  
  return (<>
  <div className="no-access-body">
    <h1>
        ACCESS DENIED
    </h1>
  </div>
  </>
   
  );
}
export default NoAccess;
